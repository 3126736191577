<template>
  <v-container class="fill-height">
    <v-row
      class="fill-height"
      align-content="center"
      justify="center"
    >
      <v-col
        class="title text-center"
        cols="12"
      >
        🤞Verifying your Email
      </v-col>
      <v-col cols="10" :md="8" :lg="5">
        <v-progress-linear
          color="success"
          indeterminate
          rounded
          height="8"
        ></v-progress-linear>
      </v-col>
      <v-col
        class="caption text-center"
        cols="12"
      >
        Please don't refresh this page.
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VERIFY_EMAIL from '../../graphql/mutations/verifyEmail.graphql'
export default {
  props: {
    token: {
      type: String
    }
  },
  mounted () {
    this.verifyToken()
  },
  methods: {
    async verifyToken () {
      try {
        const res = await this.$apollo.mutate({
          mutation: VERIFY_EMAIL,
          variables: {
            token: this.token
          }
        })
        if (res.data.verifyEmail) {
          await this.$router.replace({
            name: 'login'
          })
        }
      } catch (e) {
        return e
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
